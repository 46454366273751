var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay-modal"},[_c('portal',{attrs:{"to":"new-project-modal"}},[_c('ModalRightDynamic',{on:{"closeModal":_vm.handleClose},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('add', { msg: _vm.$t('opportunity') }))+" ")]},proxy:true},{key:"content-form",fn:function(){return [_c('v-form',{ref:"addFormProjet"},[_c('div',{staticClass:"description-bloc mb-2"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Type de projet' : 'Project type')+" ")]),_c('div',{staticClass:"description-bloc"},[_c('v-select',{staticClass:"msg-error",attrs:{"clearable":true,"value":_vm.projectToAdd.type,"items":_vm.getProjectsTypes,"required":"","loading":_vm.getProjectsTypesLoading,"validate-on-blur":"","rules":[
                v => !!v || _vm.$t('msgObligMsg', { msg: _vm.$t('typeProjet') })
              ],"item-text":"name","item-value":"id","dense":"","outlined":"","color":"#5C2DD3","item-color":"#5C2DD3","label":"Type","persistent-placeholder":true,"placeholder":_vm.$t('selectionner'),"no-data-text":_vm.$t('noDataOptionMsg', { option: _vm.$t('typeProjet') }),"menu-props":{
                bottom: true,
                offsetY: true
              }},on:{"change":function($event){return _vm.changePipelineSelonType(_vm.projectToAdd.type)}},model:{value:(_vm.projectToAdd.type),callback:function ($$v) {_vm.$set(_vm.projectToAdd, "type", $$v)},expression:"projectToAdd.type"}})],1),_c('div',{staticClass:"description-bloc"},[_c('v-autocomplete',{attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('assigned_to'),"item-color":"#5C2DD3","items":_vm.allUsers,"persistent-placeholder":true,"label":_vm.$t('assigned_to'),"item-text":"full_name","dense":"","item-value":"id","outlined":"","menu-props":{
                bottom: true,
                offsetY: true
              },"required":"","validate-on-blur":"","rules":[v => !!v || _vm.$t('assigned_to') + ' ' + _vm.$t('msgOblg')]},model:{value:(_vm.projectToAdd.project_owner),callback:function ($$v) {_vm.$set(_vm.projectToAdd, "project_owner", $$v)},expression:"projectToAdd.project_owner"}})],1),_c('div',{staticClass:"description-bloc"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Contact du projet' : 'Project contact')+" ")]),_c('div',{staticClass:"description-bloc"},[_c('v-radio-group',{staticClass:"radio-dynamique mt-0",attrs:{"hide-details":""},on:{"change":_vm.changeChoixContact},model:{value:(_vm.contactState),callback:function ($$v) {_vm.contactState=$$v},expression:"contactState"}},[_c('v-radio',{staticClass:"label-radio",attrs:{"label":_vm.$i18n.locale === 'fr'
                    ? 'Contact existant'
                    : 'Existing contact',"value":"old","color":"#5C2DD3"}}),_c('v-radio',{staticClass:"label-radio",attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Nouveau contact' : 'New contact',"value":"new","color":"#5C2DD3"}})],1)],1),(_vm.contactState == 'new')?_c('div',[_c('div',{staticClass:"description-bloc mb-2"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Détails du nouveau contact' : 'New contact details')+" ")]),_c('div',{staticClass:"description-bloc"},[_c('FormContact',{ref:"formContact",on:{"form-submitted":_vm.addFormContact}})],1)]):_c('div',[_c('div',{staticClass:"description-bloc mb-2"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Sélectionnez le contact existant' : 'Select existing contact')+" ")]),_c('div',{staticClass:"description-bloc"},[_c('v-autocomplete',{staticClass:"msg-error",attrs:{"clearable":true,"items":_vm.getCustomAllContact,"required":"","validate-on-blur":"","rules":[
                  v =>
                    !!v ||
                    _vm.$t('msgObligMsg', {
                      msg:
                        _vm.$i18n.locale === 'fr'
                          ? 'Contact existant'
                          : 'Existing contact'
                    })
                ],"return-object":"","item-text":"full_name","item-value":"id","dense":"","outlined":"","color":"#5C2DD3","item-color":"#5C2DD3","label":"Contact","persistent-placeholder":true,"placeholder":_vm.$t('selectionner'),"no-data-text":_vm.$t('noDataOptionMsg', { option: 'contact' }),"menu-props":{
                  bottom: true,
                  offsetY: true
                }},model:{value:(_vm.selectedContact),callback:function ($$v) {_vm.selectedContact=$$v},expression:"selectedContact"}})],1)]),(_vm.getFieldsAddProjetImmo && _vm.getFieldsAddProjetImmo.length && _vm.getCurentProjectTypeImmo)?_c('div',_vm._l((_vm.getFieldsAddProjetImmo),function(field){return _c('div',{key:field.id,staticClass:"description-bloc mb-2"},[_c('EditableCustomFieldCategorieInfoImmo',{attrs:{"readonly":false,"field":field,"value":null,"subCategory":_vm.getFieldsAddProjetImmo,"fieldKeyEdit":false,"dragAndDropField":true,"styleInputStandard":true},on:{"updateFunction":_vm.updateFields}})],1)}),0):_vm._e()])]},proxy:true},{key:"content-message",fn:function(){return [_c('div',{staticClass:"message"},[(_vm.getProjectsProcessing)?_c('div',{staticClass:"loading"},[_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")]):_vm._e(),(_vm.hasError)?_c('div',{staticClass:"error-msg"},[(Array.isArray(_vm.errorMessages))?_c('ul',_vm._l((_vm.errorMessages),function(e,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.errorMessages))])]):_vm._e()])]},proxy:true},{key:"content-actions",fn:function(){return [_c('v-btn',{attrs:{"color":"#45148F","dark":"","loading":_vm.loading},on:{"click":_vm.addProject}},[_c('span',{staticClass:"title-btn-edit-info text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('add', { msg: _vm.$t('opportunity') }))+" ")])]),_c('v-btn',{staticClass:"ml-3",attrs:{"text":"","variant":"outline-secondary"},on:{"click":_vm.handleClose}},[_c('span',{staticClass:"font-text bold-600 font-sz-12 color-black text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('btnCancel')))])])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }