<template>
  <div>
    <v-form ref="openModal" @submit.prevent="submitForm">
      <v-autocomplete
        :label="$t('civility')"
        dense
        :placeholder="$t('searchMsg', { msg: $t('civility') })"
        v-model="formDefault.civility"
        return-object
        :no-data-text="$t('noDataOptionMsg', { option: $t('civility') })"
        item-text="name"
        item-value="id"
        :items="[
          { id: 'Mr', name: 'Mr' },
          { id: 'Mme', name: 'Mme' },
          { id: 'Mlle', name: 'Mlle' }
        ]"
        outlined
        :persistent-placeholder="true"
        class="msg-error mt-2"
        :menu-props="{ bottom: true, offsetY: true }"
        color="#5C2DD3"
        item-color="#5C2DD3"
        validate-on-blur
        :rules="[v => !!v || $t('civility') + ' ' + $t('msgOblg')]"
      ></v-autocomplete>
      <v-text-field
        :label="$t('full_name')"
        dense
        :persistent-placeholder="true"
        outlined
        color="#5C2DD3"
        v-model="formDefault.full_name"
        item-color="#5C2DD3"
        validate-on-blur
        :rules="[v => !!v || $t('full_name') + ' ' + $t('msgOblg')]"
        @input="changeHandler('full_name', $event)"
      >
      </v-text-field>
      <v-autocomplete
        :label="$t('lead_owner')"
        dense
        :placeholder="$t('searchMsg', { msg: $t('lead_owner') })"
        v-model="formDefault.lead_owner"
        return-object
        :no-data-text="$t('noDataOptionMsg', { option: $t('lead_owner') })"
        item-text="full_name"
        item-value="id"
        :items="allUsers"
        outlined
        :persistent-placeholder="true"
        class="msg-error mt-2"
        :menu-props="{ bottom: true, offsetY: true }"
        color="#5C2DD3"
        item-color="#5C2DD3"
        validate-on-blur
        :rules="[v => !!v || $t('lead_owner') + ' ' + $t('msgOblg')]"
        @change="changeHandler('lead_owner', $event)"
      ></v-autocomplete>
      <v-text-field
        :label="$t('phone_number')"
        dense
        :persistent-placeholder="true"
        outlined
        color="#5C2DD3"
        v-model="formDefault.phone_number"
        item-color="#5C2DD3"
        @input="changeHandler('phone_number', $event)"
        validate-on-blur
        :rules="[v => !!v || $t('phone_number') + ' ' + $t('msgOblg')]"
      >
      </v-text-field>
      <v-text-field
        :label="$t('email_address')"
        dense
        :persistent-placeholder="true"
        outlined
        color="#5C2DD3"
        v-model="formDefault.email_adress"
        item-color="#5C2DD3"
        @input="changeHandler('email_adress', $event)"
        validate-on-blur
        :rules="[
          v => !!v || $t('email_address') + ' ' + $t('msgOblg'),
          v => /.+@.+\..+/.test(v) || $t('msgValidEmail')
        ]"
      >
      </v-text-field>
      <v-autocomplete
        :label="$t('job')"
        dense
        :placeholder="$t('searchMsg', { msg: $t('job') })"
        v-model="formDefault.job_title"
        return-object
        :no-data-text="$t('noDataOptionMsg', { option: $t('job') })"
        item-text="name"
        item-value="id"
        :items="getContactJob"
        :loading="getContactJobProcessing"
        outlined
        :persistent-placeholder="true"
        class="msg-error mt-2"
        :menu-props="{ bottom: true, offsetY: true }"
        color="#5C2DD3"
        item-color="#5C2DD3"
        validate-on-blur
        :rules="[v => !!v || $t('job') + ' ' + $t('msgOblg')]"
        @change="changeHandler('job_title', $event)"
      ></v-autocomplete>
      <v-autocomplete
        :label="$t('nationality')"
        dense
        :placeholder="$t('searchMsg', { msg: $t('nationality') })"
        v-model="formDefault.nationality"
        return-object
        :no-data-text="$t('noDataOptionMsg', { option: $t('nationality') })"
        item-text="name"
        item-value="id"
        :items="getContactPays"
        :loading="getContactsPaysProcessing"
        outlined
        :persistent-placeholder="true"
        class="msg-error mt-2"
        :menu-props="{ bottom: true, offsetY: true }"
        color="#5C2DD3"
        item-color="#5C2DD3"
        validate-on-blur
        :rules="[v => !!v || $t('nationality') + ' ' + $t('msgOblg')]"
        @change="changeHandler('nationality', $event)"
      ></v-autocomplete>
      <v-autocomplete
        :label="$t('location')"
        dense
        :placeholder="$t('searchMsg', { msg: $t('location') })"
        v-model="formDefault.location"
        return-object
        :no-data-text="$t('noDataOptionMsg', { option: $t('location') })"
        item-text="name"
        item-value="id"
        :items="getContactPays"
        :loading="getContactsPaysProcessing"
        outlined
        :persistent-placeholder="true"
        class="msg-error mt-2"
        :menu-props="{ bottom: true, offsetY: true }"
        color="#5C2DD3"
        item-color="#5C2DD3"
        validate-on-blur
        :rules="[v => !!v || $t('location') + ' ' + $t('msgOblg')]"
        @change="changeHandler('location', $event)"
      ></v-autocomplete>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      formDefault: {
        full_name: null,
        lead_owner: null,
        email_adress: null,
        phone_number: null,
        nationality: null,
        location: null,
        job_title: null,
        civility: null
      }
    }
  },
  methods: {
    ...mapActions(['fetchListContactPays', 'fetchListContactJob']),
    submitForm() {
      let body = {
        full_name:
          this.formDefault && this.formDefault.full_name
            ? this.formDefault.full_name
            : null,
        lead_owner:
          this.formDefault &&
          this.formDefault.lead_owner &&
          this.formDefault.lead_owner.id
            ? this.formDefault.lead_owner.id
            : null,
        email_adress:
          this.formDefault && this.formDefault.email_adress
            ? this.formDefault.email_adress
            : null,
        phone_number:
          this.formDefault && this.formDefault.phone_number
            ? this.formDefault.phone_number
            : null,
        nationality:
          this.formDefault &&
          this.formDefault.nationality &&
          this.formDefault.nationality.id
            ? this.formDefault.nationality.id
            : null,
        location:
          this.formDefault &&
          this.formDefault.location &&
          this.formDefault.location.id
            ? this.formDefault.location.id
            : null,
        job_title:
          this.formDefault &&
          this.formDefault.job_title &&
          this.formDefault.job_title.id
            ? this.formDefault.job_title.id
            : null,
        civility:
          this.formDefault &&
          this.formDefault.civility &&
          this.formDefault.civility.id
            ? this.formDefault.civility.id
            : null
      }
      // Émettre un événement contenant les données du formulaire
      this.$emit('form-submitted', body)
    },
    changeHandler(field, value) {
      // Mettre à jour formDefault lorsque les valeurs changent dans les champs de formulaire
      this.$set(this.formDefault, field, value)
      this.submitForm()
    }
  },
  computed: {
    ...mapGetters([
      'getContactPays',
      'getContactsPaysProcessing',
      'getContactJob',
      'getContactJobProcessing',
      'allUsers'
    ])
  },
  mounted() {
    this.fetchListContactPays()
    this.fetchListContactJob()
  }
}
</script>
<style scoped></style>
