<template>
  <div class="overlay-modal">
    <portal to="new-project-modal">
      <ModalRightDynamic @closeModal="handleClose">
        <template #title>
          {{ $t('add', { msg: $t('opportunity') }) }}
        </template>
        <template #content-form>
          <v-form ref="addFormProjet">
            <!-- ------------------------------------------TYPE PROJET ------------------------------------- -->
            <div class="description-bloc mb-2">
              {{ $i18n.locale === 'fr' ? 'Type de projet' : 'Project type' }}
            </div>
            <div class="description-bloc">
              <v-select
                :clearable="true"
                v-model="projectToAdd.type"
                :value="projectToAdd.type"
                :items="getProjectsTypes"
                required
                :loading="getProjectsTypesLoading"
                @change="changePipelineSelonType(projectToAdd.type)"
                validate-on-blur
                :rules="[
                  v => !!v || $t('msgObligMsg', { msg: $t('typeProjet') })
                ]"
                class="msg-error"
                item-text="name"
                item-value="id"
                dense
                outlined
                color="#5C2DD3"
                item-color="#5C2DD3"
                label="Type"
                :persistent-placeholder="true"
                :placeholder="$t('selectionner')"
                :no-data-text="
                  $t('noDataOptionMsg', { option: $t('typeProjet') })
                "
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
              >
              </v-select>
            </div>
            <!--------------------------------------------  Project Assign ------------------------------------  -->
            <div class="description-bloc">
              <v-autocomplete
                :clearable="true"
                color="#5C2DD3"
                :placeholder="$t('assigned_to')"
                item-color="#5C2DD3"
                v-model="projectToAdd.project_owner"
                :items="allUsers"
                :persistent-placeholder="true"
                :label="$t('assigned_to')"
                item-text="full_name"
                dense
                item-value="id"
                outlined
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
                required
                validate-on-blur
                :rules="[v => !!v || $t('assigned_to') + ' ' + $t('msgOblg')]"
              >
              </v-autocomplete>
            </div>
            <!-- ----------------------------------------- CHOIX CONTACT ------------------------------------- -->
            <div class="description-bloc">
              {{
                $i18n.locale === 'fr' ? 'Contact du projet' : 'Project contact'
              }}
            </div>
            <div class="description-bloc">
              <v-radio-group
                v-model="contactState"
                class="radio-dynamique mt-0"
                hide-details=""
                @change="changeChoixContact"
              >
                <v-radio
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Contact existant'
                      : 'Existing contact'
                  "
                  value="old"
                  color="#5C2DD3"
                  class="label-radio"
                ></v-radio>
                <v-radio
                  :label="
                    $i18n.locale === 'fr' ? 'Nouveau contact' : 'New contact'
                  "
                  value="new"
                  color="#5C2DD3"
                  class="label-radio"
                ></v-radio>
              </v-radio-group>
            </div>
            <!-- -------------------------------- partie contact --------------------------------- -->
            <div v-if="contactState == 'new'">
              <div class="description-bloc mb-2">
                {{
                  $i18n.locale === 'fr'
                    ? 'Détails du nouveau contact'
                    : 'New contact details'
                }}
              </div>
              <div class="description-bloc">
                <FormContact
                  @form-submitted="addFormContact"
                  ref="formContact"
                ></FormContact>
              </div>
            </div>
            <div v-else>
              <div class="description-bloc mb-2">
                {{
                  $i18n.locale === 'fr'
                    ? 'Sélectionnez le contact existant'
                    : 'Select existing contact'
                }}
              </div>
              <div class="description-bloc">
                <v-autocomplete
                  :clearable="true"
                  v-model="selectedContact"
                  :items="getCustomAllContact"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      $t('msgObligMsg', {
                        msg:
                          $i18n.locale === 'fr'
                            ? 'Contact existant'
                            : 'Existing contact'
                      })
                  ]"
                  class="msg-error"
                  return-object
                  item-text="full_name"
                  item-value="id"
                  dense
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  label="Contact"
                  :persistent-placeholder="true"
                  :placeholder="$t('selectionner')"
                  :no-data-text="$t('noDataOptionMsg', { option: 'contact' })"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </div>
            </div>
            <!-- -------------------------------- partie projet --------------------------------- -->
            <div v-if="getFieldsAddProjetImmo && getFieldsAddProjetImmo.length && getCurentProjectTypeImmo">
              <div
                v-for="field in getFieldsAddProjetImmo"
                :key="field.id"
                class="description-bloc mb-2"
              >
                <EditableCustomFieldCategorieInfoImmo
                  :readonly="false"
                  :field="field"
                  :value="null"
                  :subCategory="getFieldsAddProjetImmo"
                  @updateFunction="updateFields"
                  :fieldKeyEdit="false"
                  :dragAndDropField="true"
                  :styleInputStandard="true"
                />
              </div>
            </div> </v-form
        ></template>
        <template #content-message>
          <div class="message">
            <div v-if="getProjectsProcessing" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="hasError" class="error-msg">
              <ul v-if="Array.isArray(errorMessages)">
                <li v-for="(e, index) in errorMessages" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorMessages }}</span>
            </div>
          </div>
        </template>
        <template #content-actions>
          <v-btn color="#45148F" dark :loading="loading" @click="addProject">
            <span class="title-btn-edit-info text-capitalize">
              {{ $t('add', { msg: $t('opportunity') }) }}
            </span>
          </v-btn>
          <v-btn
            text
            class="ml-3"
            @click="handleClose"
            variant="outline-secondary"
          >
            <span
              class="font-text bold-600 font-sz-12 color-black text-capitalize"
            >
              {{ $t('btnCancel') }}</span
            >
          </v-btn>
        </template>
      </ModalRightDynamic>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import { debounce } from 'lodash'
import ModalRightDynamic from '@/views/CRMILA26/Projects/Dialogs/ModalRightDynamic.vue'
import EditableCustomFieldCategorieInfoImmo from '@/components/CrmIla26Component/EditableCustomFieldCategorieInfoImmo.vue'
import FormContact from '@/views/CRMILA26/Projects/immobilier/pipline/Contact/FormContact'
export default {
  name: 'new-opportunity-modal',
  components: {
    ModalRightDynamic,
    EditableCustomFieldCategorieInfoImmo,
    FormContact
  },
  props: {
    currentType: {
      required: true
    }
  },
  data() {
    return {
      projectToAdd: { type: null, project_owner: null },
      selectedContact: null,
      contactRequiredError: false,
      contactState: 'old',
      loading: false,
      error: null,
      premierStage: null,
      premierSubstage: null,
      fields: [],
      formContact: null,
      errorMessages: [],
      selectedNewContact: null
    }
  },
  methods: {
    ...mapActions([
      'createProject',
      'resetProjectError',
      'fetchEtapes',
      'fetchEtapeSubstage',
      'getAllUsers',
      'fetchFieldImmoSpecific',
      'fetchAllCustomContacts',
      'addCustomContact'
    ]),
    updateFields(payload) {
      this.fields = payload
    },
    async changePipelineSelonType(type_id) {
      if (type_id) {
        await this.fetchEtapes({ type_id: type_id, isNotLoading: true }).then(
          async () => {
            let etapeAvecSubStage = null
            let subStagesRemplis = null

            for (const etape of this.getEtapes) {
              subStagesRemplis = [] // Réinitialiser la liste des sub-stages remplis pour chaque nouvelle étape
              for (const stage of etape.stages) {
                await this.fetchEtapeSubstage({
                  cstage_id: stage.id,
                  stage: stage
                }) // Appel de l'API pour remplir les sub-stages de chaque stage de chaque étape

                if (stage.subStages.length > 0) {
                  etapeAvecSubStage = stage
                  subStagesRemplis = stage.subStages // Stocker les sub-stages remplis du stage actuel
                  break // Sortir de la boucle si les sub-stages de ce stage ne sont pas vides
                }
              }

              if (etapeAvecSubStage) {
                break // Sortir de la boucle externe si des sub-stages non vides sont trouvés dans une étape
              }
            }
            this.premierStage = etapeAvecSubStage
            this.premierSubstage =
              subStagesRemplis && subStagesRemplis.length
                ? subStagesRemplis[0]
                : subStagesRemplis
          }
        )
      }
    },
    async addProject() {
      this.resetProjectError()
      this.error = null
      // Validation du formulaire dans le composant FormContact
      const formContactIsValid =
        this.$refs.formContact &&
        this.$refs.formContact.$refs.openModal.validate()
      // Validation du formulaire dans le composant new-project-modal
      const newProjectFormIsValid = this.$refs.addFormProjet.validate()
      if (this.contactState == 'new') {
        if (formContactIsValid && newProjectFormIsValid) {
          const reponse = await this.addCustomContact(this.formContact)
          if (reponse && reponse.contact) {
            this.selectedNewContact = {
              id: reponse.contact.id,
              full_name: reponse.contact.full_name
            }
           
            this.validateFormProjet()
          }
        }
      } else {
        if (newProjectFormIsValid) {
          this.validateFormProjet()
        }
      }
    },
    async validateFormProjet() {
      this.loading = true
      this.contactRequiredError = false
      if (this.contactState == 'old' && !this.selectedContact) {
        this.contactRequiredError = true
        return
      }
      const data = {}
      data.dtype_id = this.projectToAdd.type
      data.project_owner = this.projectToAdd.project_owner
      if (this.getEtapes && this.getEtapes.length) {
        if (
          this.getEtapes[0].type.some(element => element.id == data.dtype_id)
        ) {
          data.step_id = this.getEtapes[0].id
          if (this.premierStage) {
            data.cstage_id = this.premierStage.id
          }
          if (this.premierSubstage) {
            data.csubstage_id = this.premierSubstage.id
          }
        }
      } else {
        this.loading = false
        this.error = 'Vous devez créer un pipeline pour ce type de projet'
        return
      }
      data.contact_id =
        this.selectedContact && this.selectedContact.id
          ? this.selectedContact.id
          : this.selectedNewContact && this.selectedNewContact.id
          ? this.selectedNewContact.id
          : null
      data.title =
        this.selectedContact && this.selectedContact.full_name
          ? this.selectedContact.full_name
          : this.selectedNewContact && this.selectedNewContact.full_name
          ? this.selectedNewContact.full_name
          : null
      // fields
      data.fields = this.fields
      const response = await this.createProject({
        currentType: this.currentType,
        data
      })
      if (response) {
        this.$emit('created', this.getProjectsLastCreated)
        this.resetNewProject()
      }
      this.loading = false
    },
    addFormContact(formData) {
      this.formContact = formData
    },
    changeChoixContact() {
      this.resetProjectError()
    },
    resetNewProject() {
      this.projectToAdd = { type: null, project_owner: null }
      this.selectedContact = null
      this.contactRequiredError = false
      this.contactState = 'old'
      this.fields = []
    },
    handleClose() {
      this.error = null
      this.$emit('close')
      this.$refs.addFormProjet.resetValidation()
      if (this.$refs.formContact && this.$refs.formContact.$refs.openModal) {
        this.$refs.formContact.$refs.openModal.resetValidation()
      }
      this.resetProjectError()
    }
  },
  computed: {
    ...mapGetters([
      'getEtapes',
      'getProjectsCreateError',
      'getProjectsLastCreated',
      'getCustomAllContact',
      'getProjectsProcessing',
      'getProjectsTypes',
      'currentUser',
      'getProjectsTypesLoading',
      'allUsers',
      'getFieldsAddProjetImmo',
      'getCustomContactsError',
      'getCurentProjectTypeImmo'
    ]),
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getProjectsCreateError(newValue) {
      this.errorMessages = this.errorMessages.concat(newValue)
    },
    error(newValue) {
      this.errorMessages = this.errorMessages.concat(newValue)
    },
    getCustomContactsError(newValue) {
      this.errorMessages = this.errorMessages.concat(newValue)
    }
  },
  async mounted() {
    // SELECTED TYPE PROJET PAR DEFAUT
    var typeProject = localStorage.getItem('typeProject')
    if (typeProject && !isNaN(typeProject)) {
      this.projectToAdd.type = parseInt(typeProject)
      await this.changePipelineSelonType(typeProject)
    }
    // SELECTED PAR DEFAUT USER CONNECTE
    // var user_id = this.currentUser.id
    // if (user_id && !isNaN(user_id)) {
    //   this.projectToAdd.project_owner = user_id
    // }
    this.getAllUsers()
    this.fetchFieldImmoSpecific()
    this.fetchAllCustomContacts()
  }
}
</script>

<style lang="scss" scoped>
.new-project-modal {
  position: fixed;
  height: calc(100% - 0px);
  transition: width 0.1s;
  width: 100%;
  top: 0px;
  border: 0;
  background: #d6d6d64a;
  right: 0;
  left: 0;
  z-index: 101;
  .new-project-content {
    position: fixed;
    width: 500px;
    right: 0px;
    height: 100%;
    background-color: #fff;
    -webkit-box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    font-size: 13px;
    color: #5e5e5e;
  }
  .close {
    position: absolute;
    font-size: 20px;
    top: 10px;
    right: 24px;
    cursor: pointer;
    z-index: 1;
  }

  .new-project-modal-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    // padding: 30px;
    font-size: 13px;
    color: #5e5e5e;
    .title-modal {
      padding: 20px;
      background-color: #f6f6f6;
    }
    .form-new-project {
      padding: 20px;
      position: relative;
      width: 100%;
      height: calc(100vh - 150px);
    }
    .action-new-projet {
      float: right;
    }
    .action-new-projet,
    .actions-msg-btn-modal {
      padding: 20px;
    }
    .description-bloc {
      position: relative;
      font-weight: 600;
      padding: 4px 10px;
    }
    .server-error {
      margin-top: 20px;
      color: #ff6961;
    }
  }

  &.regie {
    width: 800px;
    max-width: 100%;
    min-width: 400px;

    .new-project-modal-wrapper {
      overflow-y: auto;
    }
  }
  .title-btn-edit-info {
    white-space: nowrap;
    overflow: hidden;
    // width: 30vh;
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
  }
}
</style>
